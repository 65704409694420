/* @generated */
// prettier-ignore
if (Intl.DisplayNames && typeof Intl.DisplayNames.__addLocaleData === 'function') {
  Intl.DisplayNames.__addLocaleData({
  "data": {
    "patterns": {
      "locale": "{0} ({1})"
    },
    "types": {
      "calendar": {
        "long": {
          "buddhist": "calendrier bouddhiste",
          "chinese": "calendrier chinois",
          "coptic": "calendrier copte",
          "dangi": "calendrier dangi",
          "ethiopic": "calendrier éthiopien",
          "ethiopic-amete-alem": "calendrier éthiopien Amete Alem",
          "gregorian": "calendrier grégorien",
          "hebrew": "calendrier hébraïque",
          "indian": "calendrier indien",
          "islamic": "calendrier hégirien",
          "islamic-civil": "calendrier hégirien (tabulaire, époque civile)",
          "islamic-tbla": "calendrier hégirien (tabulaire, époque astronomique)",
          "islamic-umalqura": "calendrier hégirien (Umm al-Qura)",
          "iso8601": "calendrier ISO 8601",
          "japanese": "calendrier japonais",
          "persian": "calendrier persan",
          "roc": "calendrier républicain chinois"
        },
        "narrow": {
        },
        "short": {
        }
      },
      "currency": {
        "long": {
          "ADP": "peseta andorrane",
          "AED": "dirham des Émirats arabes unis",
          "AFA": "afghani (1927–2002)",
          "AFN": "afghani afghan",
          "ALK": "lek albanais (1947–1961)",
          "ALL": "lek albanais",
          "AMD": "dram arménien",
          "ANG": "florin antillais",
          "AOA": "kwanza angolais",
          "AOK": "kwanza angolais (1977–1990)",
          "AON": "nouveau kwanza angolais (1990–2000)",
          "AOR": "kwanza angolais réajusté (1995–1999)",
          "ARA": "austral argentin",
          "ARP": "peso argentin (1983–1985)",
          "ARS": "peso argentin",
          "ATS": "schilling autrichien",
          "AUD": "dollar australien",
          "AWG": "florin arubais",
          "AZM": "manat azéri (1993–2006)",
          "AZN": "manat azéri",
          "BAD": "dinar bosniaque",
          "BAM": "mark convertible bosniaque",
          "BBD": "dollar barbadien",
          "BDT": "taka bangladeshi",
          "BEC": "franc belge (convertible)",
          "BEF": "franc belge",
          "BEL": "franc belge (financier)",
          "BGL": "lev bulgare (1962–1999)",
          "BGN": "lev bulgare",
          "BHD": "dinar bahreïni",
          "BIF": "franc burundais",
          "BMD": "dollar bermudien",
          "BND": "dollar brunéien",
          "BOB": "boliviano bolivien",
          "BOP": "peso bolivien",
          "BOV": "mvdol bolivien",
          "BRB": "nouveau cruzeiro brésilien (1967–1986)",
          "BRC": "cruzado brésilien (1986–1989)",
          "BRE": "cruzeiro brésilien (1990–1993)",
          "BRL": "réal brésilien",
          "BRN": "nouveau cruzado",
          "BRR": "cruzeiro",
          "BSD": "dollar bahaméen",
          "BTN": "ngultrum bouthanais",
          "BUK": "kyat birman",
          "BWP": "pula botswanais",
          "BYB": "nouveau rouble biélorusse (1994–1999)",
          "BYN": "rouble biélorusse",
          "BYR": "rouble biélorusse (2000–2016)",
          "BZD": "dollar bélizéen",
          "CAD": "dollar canadien",
          "CDF": "franc congolais",
          "CHE": "euro WIR",
          "CHF": "franc suisse",
          "CHW": "franc WIR",
          "CLF": "unité d’investissement chilienne",
          "CLP": "peso chilien",
          "CNH": "yuan chinois (zone extracôtière)",
          "CNY": "yuan renminbi chinois",
          "COP": "peso colombien",
          "COU": "unité de valeur réelle colombienne",
          "CRC": "colón costaricain",
          "CSD": "dinar serbo-monténégrin",
          "CSK": "couronne forte tchécoslovaque",
          "CUC": "peso cubain convertible",
          "CUP": "peso cubain",
          "CVE": "escudo capverdien",
          "CYP": "livre chypriote",
          "CZK": "couronne tchèque",
          "DDM": "mark est-allemand",
          "DEM": "mark allemand",
          "DJF": "franc djiboutien",
          "DKK": "couronne danoise",
          "DOP": "peso dominicain",
          "DZD": "dinar algérien",
          "ECS": "sucre équatorien",
          "ECV": "unité de valeur constante équatoriale (UVC)",
          "EEK": "couronne estonienne",
          "EGP": "livre égyptienne",
          "ERN": "nafka érythréen",
          "ESA": "peseta espagnole (compte A)",
          "ESB": "peseta espagnole (compte convertible)",
          "ESP": "peseta espagnole",
          "ETB": "birr éthiopien",
          "EUR": "euro",
          "FIM": "mark finlandais",
          "FJD": "dollar fidjien",
          "FKP": "livre des îles Malouines",
          "FRF": "franc français",
          "GBP": "livre sterling",
          "GEK": "coupon de lari géorgien",
          "GEL": "lari géorgien",
          "GHC": "cédi",
          "GHS": "cédi ghanéen",
          "GIP": "livre de Gibraltar",
          "GMD": "dalasi gambien",
          "GNF": "franc guinéen",
          "GNS": "syli guinéen",
          "GQE": "ekwélé équatoguinéen",
          "GRD": "drachme grecque",
          "GTQ": "quetzal guatémaltèque",
          "GWE": "escudo de Guinée portugaise",
          "GWP": "peso bissau-guinéen",
          "GYD": "dollar du Guyana",
          "HKD": "dollar de Hong Kong",
          "HNL": "lempira hondurien",
          "HRD": "dinar croate",
          "HRK": "kuna croate",
          "HTG": "gourde haïtienne",
          "HUF": "forint hongrois",
          "IDR": "roupie indonésienne",
          "IEP": "livre irlandaise",
          "ILP": "livre israélienne",
          "ILS": "nouveau shekel israélien",
          "INR": "roupie indienne",
          "IQD": "dinar irakien",
          "IRR": "riyal iranien",
          "ISK": "couronne islandaise",
          "ITL": "lire italienne",
          "JMD": "dollar jamaïcain",
          "JOD": "dinar jordanien",
          "JPY": "yen japonais",
          "KES": "shilling kényan",
          "KGS": "som kirghize",
          "KHR": "riel cambodgien",
          "KMF": "franc comorien",
          "KPW": "won nord-coréen",
          "KRW": "won sud-coréen",
          "KWD": "dinar koweïtien",
          "KYD": "dollar des îles Caïmans",
          "KZT": "tenge kazakh",
          "LAK": "kip laotien",
          "LBP": "livre libanaise",
          "LKR": "roupie srilankaise",
          "LRD": "dollar libérien",
          "LSL": "loti lesothan",
          "LTL": "litas lituanien",
          "LTT": "talonas lituanien",
          "LUC": "franc convertible luxembourgeois",
          "LUF": "franc luxembourgeois",
          "LUL": "franc financier luxembourgeois",
          "LVL": "lats letton",
          "LVR": "rouble letton",
          "LYD": "dinar libyen",
          "MAD": "dirham marocain",
          "MAF": "franc marocain",
          "MDL": "leu moldave",
          "MGA": "ariary malgache",
          "MGF": "franc malgache",
          "MKD": "denar macédonien",
          "MLF": "franc malien",
          "MMK": "kyat myanmarais",
          "MNT": "tugrik mongol",
          "MOP": "pataca macanaise",
          "MRO": "ouguiya mauritanien (1973–2017)",
          "MRU": "ouguiya mauritanien",
          "MTL": "lire maltaise",
          "MTP": "livre maltaise",
          "MUR": "roupie mauricienne",
          "MVR": "rufiyaa maldivienne",
          "MWK": "kwacha malawite",
          "MXN": "peso mexicain",
          "MXP": "peso d’argent mexicain (1861–1992)",
          "MXV": "unité de conversion mexicaine (UDI)",
          "MYR": "ringgit malais",
          "MZE": "escudo mozambicain",
          "MZM": "métical",
          "MZN": "metical mozambicain",
          "NAD": "dollar namibien",
          "NGN": "naira nigérian",
          "NIC": "cordoba",
          "NIO": "córdoba oro nicaraguayen",
          "NLG": "florin néerlandais",
          "NOK": "couronne norvégienne",
          "NPR": "roupie népalaise",
          "NZD": "dollar néo-zélandais",
          "OMR": "riyal omanais",
          "PAB": "balboa panaméen",
          "PEI": "inti péruvien",
          "PEN": "sol péruvien",
          "PES": "sol péruvien (1863–1985)",
          "PGK": "kina papouan-néo-guinéen",
          "PHP": "peso philippin",
          "PKR": "roupie pakistanaise",
          "PLN": "zloty polonais",
          "PLZ": "zloty (1950–1995)",
          "PTE": "escudo portugais",
          "PYG": "guaraní paraguayen",
          "QAR": "riyal qatari",
          "RHD": "dollar rhodésien",
          "ROL": "ancien leu roumain",
          "RON": "leu roumain",
          "RSD": "dinar serbe",
          "RUB": "rouble russe",
          "RUR": "rouble russe (1991–1998)",
          "RWF": "franc rwandais",
          "SAR": "riyal saoudien",
          "SBD": "dollar des îles Salomon",
          "SCR": "roupie des Seychelles",
          "SDD": "dinar soudanais",
          "SDG": "livre soudanaise",
          "SDP": "livre soudanaise (1956–2007)",
          "SEK": "couronne suédoise",
          "SGD": "dollar de Singapour",
          "SHP": "livre de Sainte-Hélène",
          "SIT": "tolar slovène",
          "SKK": "couronne slovaque",
          "SLE": "leone sierra-léonais",
          "SLL": "leone sierra-léonais (1964—2022)",
          "SOS": "shilling somalien",
          "SRD": "dollar surinamais",
          "SRG": "florin surinamais",
          "SSP": "livre sud-soudanaise",
          "STD": "dobra santoméen (1977–2017)",
          "STN": "dobra santoméen",
          "SUR": "rouble soviétique",
          "SVC": "colón salvadorien",
          "SYP": "livre syrienne",
          "SZL": "lilangeni swazi",
          "THB": "baht thaïlandais",
          "TJR": "rouble tadjik",
          "TJS": "somoni tadjik",
          "TMM": "manat turkmène",
          "TMT": "nouveau manat turkmène",
          "TND": "dinar tunisien",
          "TOP": "pa’anga tongan",
          "TPE": "escudo timorais",
          "TRL": "livre turque (1844–2005)",
          "TRY": "livre turque",
          "TTD": "dollar de Trinité-et-Tobago",
          "TWD": "nouveau dollar taïwanais",
          "TZS": "shilling tanzanien",
          "UAH": "hryvnia ukrainienne",
          "UAK": "karbovanetz",
          "UGS": "shilling ougandais (1966–1987)",
          "UGX": "shilling ougandais",
          "USD": "dollar des États-Unis",
          "USN": "dollar des Etats-Unis (jour suivant)",
          "USS": "dollar des Etats-Unis (jour même)",
          "UYI": "peso uruguayen (unités indexées)",
          "UYP": "peso uruguayen (1975–1993)",
          "UYU": "peso uruguayen",
          "UZS": "sum ouzbek",
          "VEB": "bolivar vénézuélien (1871–2008)",
          "VEF": "bolivar vénézuélien (2008–2018)",
          "VES": "bolivar vénézuélien",
          "VND": "dông vietnamien",
          "VUV": "vatu vanuatuan",
          "WST": "tala samoan",
          "XAF": "franc CFA (BEAC)",
          "XAG": "argent",
          "XAU": "or",
          "XBA": "unité européenne composée",
          "XBB": "unité monétaire européenne",
          "XBC": "unité de compte européenne (XBC)",
          "XBD": "unité de compte européenne (XBD)",
          "XCD": "dollar des Caraïbes orientales",
          "XCG": "florin caribéen",
          "XDR": "droit de tirage spécial",
          "XEU": "unité de compte européenne (ECU)",
          "XFO": "franc or",
          "XFU": "franc UIC",
          "XOF": "franc CFA (BCEAO)",
          "XPD": "palladium",
          "XPF": "franc CFP",
          "XPT": "platine",
          "XRE": "type de fonds RINET",
          "XTS": "(devise de test)",
          "XXX": "devise inconnue ou non valide",
          "YDD": "dinar du Yémen",
          "YER": "riyal yéménite",
          "YUD": "nouveau dinar yougoslave",
          "YUM": "dinar yougoslave Noviy",
          "YUN": "dinar yougoslave convertible",
          "ZAL": "rand sud-africain (financier)",
          "ZAR": "rand sud-africain",
          "ZMK": "kwacha zambien (1968–2012)",
          "ZMW": "kwacha zambien",
          "ZRN": "nouveau zaïre zaïrien",
          "ZRZ": "zaïre zaïrois",
          "ZWD": "dollar zimbabwéen",
          "ZWL": "dollar zimbabwéen (2009)",
          "ZWR": "dollar zimbabwéen (2008)"
        },
        "narrow": {
        },
        "short": {
        }
      },
      "dateTimeField": {
        "long": {
          "day": "jour",
          "dayOfYear": "jour (année)",
          "dayperiod": "cadran",
          "era": "ère",
          "hour": "heure",
          "minute": "minute",
          "month": "mois",
          "quarter": "trimestre",
          "second": "seconde",
          "timeZoneName": "fuseau horaire",
          "weekOfMonth": "semaine (mois)",
          "weekOfYear": "semaine",
          "weekday": "jour de la semaine",
          "weekdayOfMonth": "jour (mois)",
          "year": "année"
        },
        "narrow": {
          "day": "j",
          "dayOfYear": "j (an)",
          "dayperiod": "cadran",
          "era": "ère",
          "hour": "h",
          "minute": "min",
          "month": "m.",
          "quarter": "trim.",
          "second": "s",
          "timeZoneName": "fuseau horaire",
          "weekOfMonth": "sem. (m.)",
          "weekOfYear": "sem.",
          "weekday": "j (sem.)",
          "weekdayOfMonth": "jour (mois)",
          "year": "a"
        },
        "short": {
          "day": "j",
          "dayOfYear": "j (an)",
          "dayperiod": "cadran",
          "era": "ère",
          "hour": "h",
          "minute": "min",
          "month": "m.",
          "quarter": "trim.",
          "second": "s",
          "timeZoneName": "fuseau horaire",
          "weekOfMonth": "sem. (m.)",
          "weekOfYear": "sem.",
          "weekday": "j (sem.)",
          "weekdayOfMonth": "jour (mois)",
          "year": "an"
        }
      },
      "language": {
        "dialect": {
          "long": {
            "aa": "afar",
            "ab": "abkhaze",
            "ace": "aceh",
            "ach": "acoli",
            "ada": "adangme",
            "ady": "adyguéen",
            "ae": "avestique",
            "aeb": "arabe tunisien",
            "af": "afrikaans",
            "afh": "afrihili",
            "agq": "aghem",
            "ain": "aïnou",
            "ak": "akan",
            "akk": "akkadien",
            "akz": "alabama",
            "ale": "aléoute",
            "aln": "guègue",
            "alt": "altaï du Sud",
            "am": "amharique",
            "an": "aragonais",
            "ang": "ancien anglais",
            "ann": "obolo",
            "anp": "angika",
            "ar": "arabe",
            "ar-001": "arabe standard moderne",
            "arc": "araméen",
            "arn": "mapuche",
            "aro": "araona",
            "arp": "arapaho",
            "arq": "arabe algérien",
            "ars": "arabe najdi",
            "arw": "arawak",
            "ary": "arabe marocain",
            "arz": "arabe égyptien",
            "as": "assamais",
            "asa": "asu",
            "ase": "langue des signes américaine",
            "ast": "asturien",
            "atj": "atikamekw",
            "av": "avar",
            "avk": "kotava",
            "awa": "awadhi",
            "ay": "aymara",
            "az": "azerbaïdjanais",
            "ba": "bachkir",
            "bal": "baloutchi",
            "ban": "balinais",
            "bar": "bavarois",
            "bas": "bassa",
            "bax": "bamoun",
            "bbc": "batak toba",
            "bbj": "ghomalaʼ",
            "be": "biélorusse",
            "bej": "bedja",
            "bem": "bemba",
            "bew": "betawi",
            "bez": "bena",
            "bfd": "bafut",
            "bfq": "badaga",
            "bg": "bulgare",
            "bgc": "haryanvi",
            "bgn": "baloutchi occidental",
            "bho": "bhodjpouri",
            "bi": "bichelamar",
            "bik": "bikol",
            "bin": "bini",
            "bjn": "banjar",
            "bkm": "kom",
            "bla": "siksika",
            "blo": "anii",
            "bm": "bambara",
            "bn": "bengali",
            "bo": "tibétain",
            "bpy": "bishnupriya",
            "bqi": "bakhtiari",
            "br": "breton",
            "bra": "braj",
            "brh": "brahoui",
            "brx": "bodo",
            "bs": "bosniaque",
            "bss": "akoose",
            "bua": "bouriate",
            "bug": "bugi",
            "bum": "boulou",
            "byn": "blin",
            "byv": "médumba",
            "ca": "catalan",
            "cad": "caddo",
            "car": "caribe",
            "cay": "cayuga",
            "cch": "atsam",
            "ccp": "changma kodha",
            "ce": "tchétchène",
            "ceb": "cebuano",
            "cgg": "kiga",
            "ch": "chamorro",
            "chb": "chibcha",
            "chg": "tchaghataï",
            "chk": "chuuk",
            "chm": "mari",
            "chn": "jargon chinook",
            "cho": "choctaw",
            "chp": "chipewyan",
            "chr": "cherokee",
            "chy": "cheyenne",
            "ckb": "sorani",
            "clc": "chilcotin",
            "co": "corse",
            "cop": "copte",
            "cps": "capiznon",
            "cr": "cree",
            "crg": "mitchif",
            "crh": "tatar de Crimée",
            "crj": "cri de l’Est (dialecte du Sud)",
            "crk": "cri des plaines",
            "crl": "cri de l’Est (dialecte du Nord)",
            "crm": "cri de Moose",
            "crr": "algonquin de Caroline",
            "crs": "créole seychellois",
            "cs": "tchèque",
            "csb": "kachoube",
            "csw": "cri des marais",
            "cu": "slavon d’église",
            "cv": "tchouvache",
            "cy": "gallois",
            "da": "danois",
            "dak": "dakota",
            "dar": "dargwa",
            "dav": "taita",
            "de": "allemand",
            "de-AT": "allemand autrichien",
            "de-CH": "allemand suisse",
            "del": "delaware",
            "den": "esclave",
            "dgr": "dogrib",
            "din": "dinka",
            "dje": "zarma",
            "doi": "dogri",
            "dsb": "bas-sorabe",
            "dtp": "dusun central",
            "dua": "douala",
            "dum": "moyen néerlandais",
            "dv": "maldivien",
            "dyo": "diola-fogny",
            "dyu": "dioula",
            "dz": "dzongkha",
            "dzg": "dazaga",
            "ebu": "embu",
            "ee": "éwé",
            "efi": "éfik",
            "egl": "émilien",
            "egy": "égyptien ancien",
            "eka": "ékadjouk",
            "el": "grec",
            "elx": "élamite",
            "en": "anglais",
            "en-AU": "anglais australien",
            "en-CA": "anglais canadien",
            "en-GB": "anglais britannique",
            "en-US": "anglais américain",
            "enm": "moyen anglais",
            "eo": "espéranto",
            "es": "espagnol",
            "es-419": "espagnol d’Amérique latine",
            "es-ES": "espagnol d’Espagne",
            "es-MX": "espagnol du Mexique",
            "esu": "youpik central",
            "et": "estonien",
            "eu": "basque",
            "ewo": "éwondo",
            "ext": "estrémègne",
            "fa": "persan",
            "fa-AF": "dari",
            "fan": "fang",
            "fat": "fanti",
            "ff": "peul",
            "fi": "finnois",
            "fil": "filipino",
            "fit": "finnois tornédalien",
            "fj": "fidjien",
            "fo": "féroïen",
            "fon": "fon",
            "fr": "français",
            "fr-CA": "français canadien",
            "fr-CH": "français suisse",
            "frc": "français cadien",
            "frm": "moyen français",
            "fro": "ancien français",
            "frp": "francoprovençal",
            "frr": "frison septentrional",
            "frs": "frison oriental",
            "fur": "frioulan",
            "fy": "frison occidental",
            "ga": "irlandais",
            "gaa": "ga",
            "gag": "gagaouze",
            "gan": "gan",
            "gay": "gayo",
            "gba": "gbaya",
            "gbz": "dari zoroastrien",
            "gd": "gaélique écossais",
            "gez": "guèze",
            "gil": "gilbertin",
            "gl": "galicien",
            "glk": "gilaki",
            "gmh": "moyen haut-allemand",
            "gn": "guarani",
            "goh": "ancien haut allemand",
            "gon": "gondi",
            "gor": "gorontalo",
            "got": "gotique",
            "grb": "grebo",
            "grc": "grec ancien",
            "gsw": "suisse allemand",
            "gu": "goudjarati",
            "guc": "wayuu",
            "gur": "gurenne",
            "guz": "gusii",
            "gv": "mannois",
            "gwi": "gwichʼin",
            "ha": "haoussa",
            "hai": "haïda",
            "hak": "hakka",
            "haw": "hawaïen",
            "hax": "haïda du Sud",
            "he": "hébreu",
            "hi": "hindi",
            "hif": "hindi fidjien",
            "hil": "hiligaynon",
            "hit": "hittite",
            "hmn": "hmong",
            "ho": "hiri motu",
            "hr": "croate",
            "hsb": "haut-sorabe",
            "hsn": "xiang",
            "ht": "créole haïtien",
            "hu": "hongrois",
            "hup": "hupa",
            "hur": "halkomelem",
            "hy": "arménien",
            "hz": "héréro",
            "ia": "interlingua",
            "iba": "iban",
            "ibb": "ibibio",
            "id": "indonésien",
            "ie": "interlingue",
            "ig": "igbo",
            "ii": "yi du Sichuan",
            "ik": "inupiaq",
            "ikt": "inuktitut de l’Ouest canadien",
            "ilo": "ilocano",
            "inh": "ingouche",
            "io": "ido",
            "is": "islandais",
            "it": "italien",
            "iu": "inuktitut",
            "izh": "ingrien",
            "ja": "japonais",
            "jam": "créole jamaïcain",
            "jbo": "lojban",
            "jgo": "ngomba",
            "jmc": "matchamé",
            "jpr": "judéo-persan",
            "jrb": "judéo-arabe",
            "jut": "jute",
            "jv": "javanais",
            "ka": "géorgien",
            "kaa": "karakalpak",
            "kab": "kabyle",
            "kac": "kachin",
            "kaj": "jju",
            "kam": "kamba",
            "kaw": "kawi",
            "kbd": "kabarde",
            "kbl": "kanembou",
            "kcg": "tyap",
            "kde": "makondé",
            "kea": "capverdien",
            "ken": "kényang",
            "kfo": "koro",
            "kg": "kikongo",
            "kgp": "caingangue",
            "kha": "khasi",
            "kho": "khotanais",
            "khq": "koyra chiini",
            "khw": "khowar",
            "ki": "kikuyu",
            "kiu": "kirmanjki",
            "kj": "kuanyama",
            "kk": "kazakh",
            "kkj": "kako",
            "kl": "groenlandais",
            "kln": "kalendjin",
            "km": "khmer",
            "kmb": "kimboundou",
            "kn": "kannada",
            "ko": "coréen",
            "koi": "komi-permiak",
            "kok": "konkani",
            "kos": "kosraéen",
            "kpe": "kpellé",
            "kr": "kanouri",
            "krc": "karatchaï balkar",
            "kri": "krio",
            "krj": "kinaray-a",
            "krl": "carélien",
            "kru": "kouroukh",
            "ks": "cachemiri",
            "ksb": "shambala",
            "ksf": "bafia",
            "ksh": "kölsch",
            "ku": "kurde",
            "kum": "koumyk",
            "kut": "kutenai",
            "kv": "komi",
            "kw": "cornique",
            "kwk": "kwak’wala",
            "kxv": "kuvi",
            "ky": "kirghize",
            "la": "latin",
            "lad": "ladino",
            "lag": "langi",
            "lah": "lahnda",
            "lam": "lamba",
            "lb": "luxembourgeois",
            "lez": "lezghien",
            "lfn": "lingua franca nova",
            "lg": "ganda",
            "li": "limbourgeois",
            "lij": "ligure",
            "lil": "lillooet",
            "liv": "livonien",
            "lkt": "lakota",
            "lmo": "lombard",
            "ln": "lingala",
            "lo": "lao",
            "lol": "mongo",
            "lou": "créole louisianais",
            "loz": "lozi",
            "lrc": "lori du Nord",
            "lsm": "samia",
            "lt": "lituanien",
            "ltg": "latgalien",
            "lu": "luba-katanga (kiluba)",
            "lua": "luba-kasaï (ciluba)",
            "lui": "luiseño",
            "lun": "lunda",
            "lus": "lushaï",
            "luy": "luyia",
            "lv": "letton",
            "lzh": "chinois littéraire",
            "lzz": "laze",
            "mad": "madurais",
            "maf": "mafa",
            "mag": "magahi",
            "mai": "maïthili",
            "mak": "makassar",
            "man": "mandingue",
            "mas": "maasaï",
            "mde": "maba",
            "mdf": "mokcha",
            "mdr": "mandar",
            "men": "mendé",
            "mer": "meru",
            "mfe": "créole mauricien",
            "mg": "malgache",
            "mga": "moyen irlandais",
            "mgh": "makua",
            "mgo": "metaʼ",
            "mh": "marshallais",
            "mi": "maori",
            "mic": "micmac",
            "min": "minangkabau",
            "mk": "macédonien",
            "ml": "malayalam",
            "mn": "mongol",
            "mnc": "mandchou",
            "mni": "manipuri",
            "moe": "innu-aimun",
            "moh": "mohawk",
            "mos": "moré",
            "mr": "marathi",
            "mrj": "mari occidental",
            "ms": "malais",
            "mt": "maltais",
            "mua": "moundang",
            "mul": "multilingue",
            "mus": "creek",
            "mwl": "mirandais",
            "mwr": "marwarî",
            "mwv": "mentawaï",
            "my": "birman",
            "mye": "myènè",
            "myv": "erzya",
            "mzn": "mazandérani",
            "na": "nauruan",
            "nan": "minnan",
            "nap": "napolitain",
            "naq": "nama",
            "nb": "norvégien bokmål",
            "nd": "ndébélé du Nord",
            "nds": "bas-allemand",
            "nds-NL": "bas-saxon néerlandais",
            "ne": "népalais",
            "new": "newari",
            "ng": "ndonga",
            "nia": "niha",
            "niu": "niuéen",
            "njo": "ao",
            "nl": "néerlandais",
            "nl-BE": "flamand",
            "nmg": "ngoumba",
            "nn": "norvégien nynorsk",
            "nnh": "ngiemboon",
            "no": "norvégien",
            "nog": "nogaï",
            "non": "vieux norrois",
            "nov": "novial",
            "nqo": "n’ko",
            "nr": "ndébélé du Sud",
            "nso": "sotho du Nord",
            "nus": "nuer",
            "nv": "navajo",
            "nwc": "newarî classique",
            "ny": "chewa",
            "nym": "nyamwezi",
            "nyn": "nyankolé",
            "nyo": "nyoro",
            "nzi": "nzema",
            "oc": "occitan",
            "oj": "ojibwa",
            "ojb": "ojibwé du Nord-Ouest",
            "ojc": "ojibwé central",
            "ojs": "oji-cri",
            "ojw": "ojibwé occidental",
            "oka": "colville-okanagan",
            "om": "oromo",
            "or": "odia",
            "os": "ossète",
            "osa": "osage",
            "ota": "turc ottoman",
            "pa": "pendjabi",
            "pag": "pangasinan",
            "pal": "pahlavi",
            "pam": "pampangan",
            "pap": "papiamento",
            "pau": "palau",
            "pcd": "picard",
            "pcm": "pidgin nigérian",
            "pdc": "pennsilfaanisch",
            "pdt": "bas-prussien",
            "peo": "persan ancien",
            "pfl": "allemand palatin",
            "phn": "phénicien",
            "pi": "pali",
            "pis": "pijin",
            "pl": "polonais",
            "pms": "piémontais",
            "pnt": "pontique",
            "pon": "pohnpei",
            "pqm": "malécite-passamaquoddy",
            "prg": "prussien",
            "pro": "provençal ancien",
            "ps": "pachto",
            "pt": "portugais",
            "pt-BR": "portugais brésilien",
            "pt-PT": "portugais européen",
            "qu": "quechua",
            "quc": "quiché",
            "qug": "quichua du Haut-Chimborazo",
            "raj": "rajasthani",
            "rap": "rapanui",
            "rar": "rarotongien",
            "rgn": "romagnol",
            "rhg": "rohingya",
            "rif": "rifain",
            "rm": "romanche",
            "rn": "roundi",
            "ro": "roumain",
            "ro-MD": "moldave",
            "rof": "rombo",
            "rom": "romani",
            "rtm": "rotuman",
            "ru": "russe",
            "rue": "ruthène",
            "rug": "roviana",
            "rup": "aroumain",
            "rw": "kinyarwanda",
            "rwk": "rwa",
            "sa": "sanskrit",
            "sad": "sandawe",
            "sah": "iakoute",
            "sam": "araméen samaritain",
            "saq": "samburu",
            "sas": "sasak",
            "sat": "santali",
            "saz": "saurashtra",
            "sba": "ngambay",
            "sbp": "isangu",
            "sc": "sarde",
            "scn": "sicilien",
            "sco": "écossais",
            "sd": "sindhi",
            "sdc": "sarde sassarais",
            "sdh": "kurde du Sud",
            "se": "same du Nord",
            "see": "seneca",
            "seh": "cisena",
            "sei": "séri",
            "sel": "selkoupe",
            "ses": "koyraboro senni",
            "sg": "sango",
            "sga": "ancien irlandais",
            "sgs": "samogitien",
            "sh": "serbo-croate",
            "shi": "chleuh",
            "shn": "shan",
            "shu": "arabe tchadien",
            "si": "cingalais",
            "sid": "sidamo",
            "sk": "slovaque",
            "sl": "slovène",
            "slh": "lushootseed du Sud",
            "sli": "bas-silésien",
            "sly": "sélayar",
            "sm": "samoan",
            "sma": "same du Sud",
            "smj": "same de Lule",
            "smn": "same d’Inari",
            "sms": "same skolt",
            "sn": "shona",
            "snk": "soninké",
            "so": "somali",
            "sog": "sogdien",
            "sq": "albanais",
            "sr": "serbe",
            "srn": "sranan tongo",
            "srr": "sérère",
            "ss": "swati",
            "ssy": "saho",
            "st": "sotho du Sud",
            "stq": "saterlandais",
            "str": "salish des détroits",
            "su": "soundanais",
            "suk": "soukouma",
            "sus": "soussou",
            "sux": "sumérien",
            "sv": "suédois",
            "sw": "swahili",
            "sw-CD": "swahili du Congo",
            "swb": "comorien",
            "syc": "syriaque classique",
            "syr": "syriaque",
            "szl": "silésien",
            "ta": "tamoul",
            "tce": "tutchone du Sud",
            "tcy": "toulou",
            "te": "télougou",
            "tem": "timné",
            "teo": "teso",
            "ter": "tereno",
            "tet": "tétoum",
            "tg": "tadjik",
            "tgx": "tagish",
            "th": "thaï",
            "tht": "tahltan",
            "ti": "tigrigna",
            "tig": "tigré",
            "tiv": "tiv",
            "tk": "turkmène",
            "tkl": "tokelau",
            "tkr": "tsakhour",
            "tl": "tagalog",
            "tlh": "klingon",
            "tli": "tlingit",
            "tly": "talysh",
            "tmh": "tamacheq",
            "tn": "tswana",
            "to": "tongien",
            "tog": "tonga nyasa",
            "tok": "toki pona",
            "tpi": "tok pisin",
            "tr": "turc",
            "tru": "touroyo",
            "trv": "taroko",
            "ts": "tsonga",
            "tsd": "tsakonien",
            "tsi": "tsimshian",
            "tt": "tatar",
            "ttm": "tutchone du Nord",
            "ttt": "tati caucasien",
            "tum": "tumbuka",
            "tvl": "tuvalu",
            "tw": "twi",
            "twq": "tasawaq",
            "ty": "tahitien",
            "tyv": "touvain",
            "tzm": "amazighe de l’Atlas central",
            "udm": "oudmourte",
            "ug": "ouïghour",
            "uga": "ougaritique",
            "uk": "ukrainien",
            "umb": "umbundu",
            "und": "langue indéterminée",
            "ur": "ourdou",
            "uz": "ouzbek",
            "vai": "vaï",
            "ve": "venda",
            "vec": "vénitien",
            "vep": "vepse",
            "vi": "vietnamien",
            "vls": "flamand occidental",
            "vmf": "franconien du Main",
            "vmw": "macua",
            "vo": "volapük",
            "vot": "vote",
            "vro": "võro",
            "vun": "vunjo",
            "wa": "wallon",
            "wae": "walser",
            "wal": "walamo",
            "war": "waray",
            "was": "washo",
            "wbp": "warlpiri",
            "wo": "wolof",
            "wuu": "wu",
            "xal": "kalmouk",
            "xh": "xhosa",
            "xmf": "mingrélien",
            "xnr": "kangri",
            "xog": "soga",
            "yao": "yao",
            "yap": "yapois",
            "yav": "yangben",
            "ybb": "yemba",
            "yi": "yiddish",
            "yo": "yoruba",
            "yrl": "nheengatou",
            "yue": "cantonais",
            "za": "zhuang",
            "zap": "zapotèque",
            "zbl": "symboles Bliss",
            "zea": "zélandais",
            "zen": "zenaga",
            "zgh": "amazighe standard marocain",
            "zh": "chinois",
            "zh-Hans": "chinois simplifié",
            "zh-Hant": "chinois traditionnel",
            "zu": "zoulou",
            "zun": "zuñi",
            "zxx": "sans contenu linguistique",
            "zza": "zazaki"
          },
          "narrow": {
          },
          "short": {
            "az": "azéri",
            "en-GB": "anglais britannique",
            "en-US": "anglais américain"
          }
        },
        "standard": {
          "long": {
            "aa": "afar",
            "ab": "abkhaze",
            "ace": "aceh",
            "ach": "acoli",
            "ada": "adangme",
            "ady": "adyguéen",
            "ae": "avestique",
            "aeb": "arabe tunisien",
            "af": "afrikaans",
            "afh": "afrihili",
            "agq": "aghem",
            "ain": "aïnou",
            "ak": "akan",
            "akk": "akkadien",
            "akz": "alabama",
            "ale": "aléoute",
            "aln": "guègue",
            "alt": "altaï du Sud",
            "am": "amharique",
            "an": "aragonais",
            "ang": "ancien anglais",
            "ann": "obolo",
            "anp": "angika",
            "ar": "arabe",
            "ar-001": "arabe (Monde)",
            "arc": "araméen",
            "arn": "mapuche",
            "aro": "araona",
            "arp": "arapaho",
            "arq": "arabe algérien",
            "ars": "arabe najdi",
            "arw": "arawak",
            "ary": "arabe marocain",
            "arz": "arabe égyptien",
            "as": "assamais",
            "asa": "asu",
            "ase": "langue des signes américaine",
            "ast": "asturien",
            "atj": "atikamekw",
            "av": "avar",
            "avk": "kotava",
            "awa": "awadhi",
            "ay": "aymara",
            "az": "azerbaïdjanais",
            "ba": "bachkir",
            "bal": "baloutchi",
            "ban": "balinais",
            "bar": "bavarois",
            "bas": "bassa",
            "bax": "bamoun",
            "bbc": "batak toba",
            "bbj": "ghomalaʼ",
            "be": "biélorusse",
            "bej": "bedja",
            "bem": "bemba",
            "bew": "betawi",
            "bez": "bena",
            "bfd": "bafut",
            "bfq": "badaga",
            "bg": "bulgare",
            "bgc": "haryanvi",
            "bgn": "baloutchi occidental",
            "bho": "bhodjpouri",
            "bi": "bichelamar",
            "bik": "bikol",
            "bin": "bini",
            "bjn": "banjar",
            "bkm": "kom",
            "bla": "siksika",
            "blo": "anii",
            "bm": "bambara",
            "bn": "bengali",
            "bo": "tibétain",
            "bpy": "bishnupriya",
            "bqi": "bakhtiari",
            "br": "breton",
            "bra": "braj",
            "brh": "brahoui",
            "brx": "bodo",
            "bs": "bosniaque",
            "bss": "akoose",
            "bua": "bouriate",
            "bug": "bugi",
            "bum": "boulou",
            "byn": "blin",
            "byv": "médumba",
            "ca": "catalan",
            "cad": "caddo",
            "car": "caribe",
            "cay": "cayuga",
            "cch": "atsam",
            "ccp": "changma kodha",
            "ce": "tchétchène",
            "ceb": "cebuano",
            "cgg": "kiga",
            "ch": "chamorro",
            "chb": "chibcha",
            "chg": "tchaghataï",
            "chk": "chuuk",
            "chm": "mari",
            "chn": "jargon chinook",
            "cho": "choctaw",
            "chp": "chipewyan",
            "chr": "cherokee",
            "chy": "cheyenne",
            "ckb": "sorani",
            "clc": "chilcotin",
            "co": "corse",
            "cop": "copte",
            "cps": "capiznon",
            "cr": "cree",
            "crg": "mitchif",
            "crh": "tatar de Crimée",
            "crj": "cri de l’Est (dialecte du Sud)",
            "crk": "cri des plaines",
            "crl": "cri de l’Est (dialecte du Nord)",
            "crm": "cri de Moose",
            "crr": "algonquin de Caroline",
            "crs": "créole seychellois",
            "cs": "tchèque",
            "csb": "kachoube",
            "csw": "cri des marais",
            "cu": "slavon d’église",
            "cv": "tchouvache",
            "cy": "gallois",
            "da": "danois",
            "dak": "dakota",
            "dar": "dargwa",
            "dav": "taita",
            "de": "allemand",
            "de-AT": "allemand (Autriche)",
            "de-CH": "allemand (Suisse)",
            "del": "delaware",
            "den": "esclave",
            "dgr": "dogrib",
            "din": "dinka",
            "dje": "zarma",
            "doi": "dogri",
            "dsb": "bas-sorabe",
            "dtp": "dusun central",
            "dua": "douala",
            "dum": "moyen néerlandais",
            "dv": "maldivien",
            "dyo": "diola-fogny",
            "dyu": "dioula",
            "dz": "dzongkha",
            "dzg": "dazaga",
            "ebu": "embu",
            "ee": "éwé",
            "efi": "éfik",
            "egl": "émilien",
            "egy": "égyptien ancien",
            "eka": "ékadjouk",
            "el": "grec",
            "elx": "élamite",
            "en": "anglais",
            "en-AU": "anglais (Australie)",
            "en-CA": "anglais (Canada)",
            "en-GB": "anglais (Royaume-Uni)",
            "en-US": "anglais (États-Unis)",
            "enm": "moyen anglais",
            "eo": "espéranto",
            "es": "espagnol",
            "es-419": "espagnol (Amérique latine)",
            "es-ES": "espagnol (Espagne)",
            "es-MX": "espagnol (Mexique)",
            "esu": "youpik central",
            "et": "estonien",
            "eu": "basque",
            "ewo": "éwondo",
            "ext": "estrémègne",
            "fa": "persan",
            "fa-AF": "persan (Afghanistan)",
            "fan": "fang",
            "fat": "fanti",
            "ff": "peul",
            "fi": "finnois",
            "fil": "filipino",
            "fit": "finnois tornédalien",
            "fj": "fidjien",
            "fo": "féroïen",
            "fon": "fon",
            "fr": "français",
            "fr-CA": "français (Canada)",
            "fr-CH": "français (Suisse)",
            "frc": "français cadien",
            "frm": "moyen français",
            "fro": "ancien français",
            "frp": "francoprovençal",
            "frr": "frison septentrional",
            "frs": "frison oriental",
            "fur": "frioulan",
            "fy": "frison occidental",
            "ga": "irlandais",
            "gaa": "ga",
            "gag": "gagaouze",
            "gan": "gan",
            "gay": "gayo",
            "gba": "gbaya",
            "gbz": "dari zoroastrien",
            "gd": "gaélique écossais",
            "gez": "guèze",
            "gil": "gilbertin",
            "gl": "galicien",
            "glk": "gilaki",
            "gmh": "moyen haut-allemand",
            "gn": "guarani",
            "goh": "ancien haut allemand",
            "gon": "gondi",
            "gor": "gorontalo",
            "got": "gotique",
            "grb": "grebo",
            "grc": "grec ancien",
            "gsw": "suisse allemand",
            "gu": "goudjarati",
            "guc": "wayuu",
            "gur": "gurenne",
            "guz": "gusii",
            "gv": "mannois",
            "gwi": "gwichʼin",
            "ha": "haoussa",
            "hai": "haïda",
            "hak": "hakka",
            "haw": "hawaïen",
            "hax": "haïda du Sud",
            "he": "hébreu",
            "hi": "hindi",
            "hif": "hindi fidjien",
            "hil": "hiligaynon",
            "hit": "hittite",
            "hmn": "hmong",
            "ho": "hiri motu",
            "hr": "croate",
            "hsb": "haut-sorabe",
            "hsn": "xiang",
            "ht": "créole haïtien",
            "hu": "hongrois",
            "hup": "hupa",
            "hur": "halkomelem",
            "hy": "arménien",
            "hz": "héréro",
            "ia": "interlingua",
            "iba": "iban",
            "ibb": "ibibio",
            "id": "indonésien",
            "ie": "interlingue",
            "ig": "igbo",
            "ii": "yi du Sichuan",
            "ik": "inupiaq",
            "ikt": "inuktitut de l’Ouest canadien",
            "ilo": "ilocano",
            "inh": "ingouche",
            "io": "ido",
            "is": "islandais",
            "it": "italien",
            "iu": "inuktitut",
            "izh": "ingrien",
            "ja": "japonais",
            "jam": "créole jamaïcain",
            "jbo": "lojban",
            "jgo": "ngomba",
            "jmc": "matchamé",
            "jpr": "judéo-persan",
            "jrb": "judéo-arabe",
            "jut": "jute",
            "jv": "javanais",
            "ka": "géorgien",
            "kaa": "karakalpak",
            "kab": "kabyle",
            "kac": "kachin",
            "kaj": "jju",
            "kam": "kamba",
            "kaw": "kawi",
            "kbd": "kabarde",
            "kbl": "kanembou",
            "kcg": "tyap",
            "kde": "makondé",
            "kea": "capverdien",
            "ken": "kényang",
            "kfo": "koro",
            "kg": "kikongo",
            "kgp": "caingangue",
            "kha": "khasi",
            "kho": "khotanais",
            "khq": "koyra chiini",
            "khw": "khowar",
            "ki": "kikuyu",
            "kiu": "kirmanjki",
            "kj": "kuanyama",
            "kk": "kazakh",
            "kkj": "kako",
            "kl": "groenlandais",
            "kln": "kalendjin",
            "km": "khmer",
            "kmb": "kimboundou",
            "kn": "kannada",
            "ko": "coréen",
            "koi": "komi-permiak",
            "kok": "konkani",
            "kos": "kosraéen",
            "kpe": "kpellé",
            "kr": "kanouri",
            "krc": "karatchaï balkar",
            "kri": "krio",
            "krj": "kinaray-a",
            "krl": "carélien",
            "kru": "kouroukh",
            "ks": "cachemiri",
            "ksb": "shambala",
            "ksf": "bafia",
            "ksh": "kölsch",
            "ku": "kurde",
            "kum": "koumyk",
            "kut": "kutenai",
            "kv": "komi",
            "kw": "cornique",
            "kwk": "kwak’wala",
            "kxv": "kuvi",
            "ky": "kirghize",
            "la": "latin",
            "lad": "ladino",
            "lag": "langi",
            "lah": "lahnda",
            "lam": "lamba",
            "lb": "luxembourgeois",
            "lez": "lezghien",
            "lfn": "lingua franca nova",
            "lg": "ganda",
            "li": "limbourgeois",
            "lij": "ligure",
            "lil": "lillooet",
            "liv": "livonien",
            "lkt": "lakota",
            "lmo": "lombard",
            "ln": "lingala",
            "lo": "lao",
            "lol": "mongo",
            "lou": "créole louisianais",
            "loz": "lozi",
            "lrc": "lori du Nord",
            "lsm": "samia",
            "lt": "lituanien",
            "ltg": "latgalien",
            "lu": "luba-katanga (kiluba)",
            "lua": "luba-kasaï (ciluba)",
            "lui": "luiseño",
            "lun": "lunda",
            "lus": "lushaï",
            "luy": "luyia",
            "lv": "letton",
            "lzh": "chinois littéraire",
            "lzz": "laze",
            "mad": "madurais",
            "maf": "mafa",
            "mag": "magahi",
            "mai": "maïthili",
            "mak": "makassar",
            "man": "mandingue",
            "mas": "maasaï",
            "mde": "maba",
            "mdf": "mokcha",
            "mdr": "mandar",
            "men": "mendé",
            "mer": "meru",
            "mfe": "créole mauricien",
            "mg": "malgache",
            "mga": "moyen irlandais",
            "mgh": "makua",
            "mgo": "metaʼ",
            "mh": "marshallais",
            "mi": "maori",
            "mic": "micmac",
            "min": "minangkabau",
            "mk": "macédonien",
            "ml": "malayalam",
            "mn": "mongol",
            "mnc": "mandchou",
            "mni": "manipuri",
            "moe": "innu-aimun",
            "moh": "mohawk",
            "mos": "moré",
            "mr": "marathi",
            "mrj": "mari occidental",
            "ms": "malais",
            "mt": "maltais",
            "mua": "moundang",
            "mul": "multilingue",
            "mus": "creek",
            "mwl": "mirandais",
            "mwr": "marwarî",
            "mwv": "mentawaï",
            "my": "birman",
            "mye": "myènè",
            "myv": "erzya",
            "mzn": "mazandérani",
            "na": "nauruan",
            "nan": "minnan",
            "nap": "napolitain",
            "naq": "nama",
            "nb": "norvégien bokmål",
            "nd": "ndébélé du Nord",
            "nds": "bas-allemand",
            "nds-NL": "bas-allemand (Pays-Bas)",
            "ne": "népalais",
            "new": "newari",
            "ng": "ndonga",
            "nia": "niha",
            "niu": "niuéen",
            "njo": "ao",
            "nl": "néerlandais",
            "nl-BE": "néerlandais (Belgique)",
            "nmg": "ngoumba",
            "nn": "norvégien nynorsk",
            "nnh": "ngiemboon",
            "no": "norvégien",
            "nog": "nogaï",
            "non": "vieux norrois",
            "nov": "novial",
            "nqo": "n’ko",
            "nr": "ndébélé du Sud",
            "nso": "sotho du Nord",
            "nus": "nuer",
            "nv": "navajo",
            "nwc": "newarî classique",
            "ny": "chewa",
            "nym": "nyamwezi",
            "nyn": "nyankolé",
            "nyo": "nyoro",
            "nzi": "nzema",
            "oc": "occitan",
            "oj": "ojibwa",
            "ojb": "ojibwé du Nord-Ouest",
            "ojc": "ojibwé central",
            "ojs": "oji-cri",
            "ojw": "ojibwé occidental",
            "oka": "colville-okanagan",
            "om": "oromo",
            "or": "odia",
            "os": "ossète",
            "osa": "osage",
            "ota": "turc ottoman",
            "pa": "pendjabi",
            "pag": "pangasinan",
            "pal": "pahlavi",
            "pam": "pampangan",
            "pap": "papiamento",
            "pau": "palau",
            "pcd": "picard",
            "pcm": "pidgin nigérian",
            "pdc": "pennsilfaanisch",
            "pdt": "bas-prussien",
            "peo": "persan ancien",
            "pfl": "allemand palatin",
            "phn": "phénicien",
            "pi": "pali",
            "pis": "pijin",
            "pl": "polonais",
            "pms": "piémontais",
            "pnt": "pontique",
            "pon": "pohnpei",
            "pqm": "malécite-passamaquoddy",
            "prg": "prussien",
            "pro": "provençal ancien",
            "ps": "pachto",
            "pt": "portugais",
            "pt-BR": "portugais (Brésil)",
            "pt-PT": "portugais (Portugal)",
            "qu": "quechua",
            "quc": "quiché",
            "qug": "quichua du Haut-Chimborazo",
            "raj": "rajasthani",
            "rap": "rapanui",
            "rar": "rarotongien",
            "rgn": "romagnol",
            "rhg": "rohingya",
            "rif": "rifain",
            "rm": "romanche",
            "rn": "roundi",
            "ro": "roumain",
            "ro-MD": "roumain (Moldavie)",
            "rof": "rombo",
            "rom": "romani",
            "rtm": "rotuman",
            "ru": "russe",
            "rue": "ruthène",
            "rug": "roviana",
            "rup": "aroumain",
            "rw": "kinyarwanda",
            "rwk": "rwa",
            "sa": "sanskrit",
            "sad": "sandawe",
            "sah": "iakoute",
            "sam": "araméen samaritain",
            "saq": "samburu",
            "sas": "sasak",
            "sat": "santali",
            "saz": "saurashtra",
            "sba": "ngambay",
            "sbp": "isangu",
            "sc": "sarde",
            "scn": "sicilien",
            "sco": "écossais",
            "sd": "sindhi",
            "sdc": "sarde sassarais",
            "sdh": "kurde du Sud",
            "se": "same du Nord",
            "see": "seneca",
            "seh": "cisena",
            "sei": "séri",
            "sel": "selkoupe",
            "ses": "koyraboro senni",
            "sg": "sango",
            "sga": "ancien irlandais",
            "sgs": "samogitien",
            "sh": "serbo-croate",
            "shi": "chleuh",
            "shn": "shan",
            "shu": "arabe tchadien",
            "si": "cingalais",
            "sid": "sidamo",
            "sk": "slovaque",
            "sl": "slovène",
            "slh": "lushootseed du Sud",
            "sli": "bas-silésien",
            "sly": "sélayar",
            "sm": "samoan",
            "sma": "same du Sud",
            "smj": "same de Lule",
            "smn": "same d’Inari",
            "sms": "same skolt",
            "sn": "shona",
            "snk": "soninké",
            "so": "somali",
            "sog": "sogdien",
            "sq": "albanais",
            "sr": "serbe",
            "srn": "sranan tongo",
            "srr": "sérère",
            "ss": "swati",
            "ssy": "saho",
            "st": "sotho du Sud",
            "stq": "saterlandais",
            "str": "salish des détroits",
            "su": "soundanais",
            "suk": "soukouma",
            "sus": "soussou",
            "sux": "sumérien",
            "sv": "suédois",
            "sw": "swahili",
            "sw-CD": "swahili (Congo-Kinshasa)",
            "swb": "comorien",
            "syc": "syriaque classique",
            "syr": "syriaque",
            "szl": "silésien",
            "ta": "tamoul",
            "tce": "tutchone du Sud",
            "tcy": "toulou",
            "te": "télougou",
            "tem": "timné",
            "teo": "teso",
            "ter": "tereno",
            "tet": "tétoum",
            "tg": "tadjik",
            "tgx": "tagish",
            "th": "thaï",
            "tht": "tahltan",
            "ti": "tigrigna",
            "tig": "tigré",
            "tiv": "tiv",
            "tk": "turkmène",
            "tkl": "tokelau",
            "tkr": "tsakhour",
            "tl": "tagalog",
            "tlh": "klingon",
            "tli": "tlingit",
            "tly": "talysh",
            "tmh": "tamacheq",
            "tn": "tswana",
            "to": "tongien",
            "tog": "tonga nyasa",
            "tok": "toki pona",
            "tpi": "tok pisin",
            "tr": "turc",
            "tru": "touroyo",
            "trv": "taroko",
            "ts": "tsonga",
            "tsd": "tsakonien",
            "tsi": "tsimshian",
            "tt": "tatar",
            "ttm": "tutchone du Nord",
            "ttt": "tati caucasien",
            "tum": "tumbuka",
            "tvl": "tuvalu",
            "tw": "twi",
            "twq": "tasawaq",
            "ty": "tahitien",
            "tyv": "touvain",
            "tzm": "amazighe de l’Atlas central",
            "udm": "oudmourte",
            "ug": "ouïghour",
            "uga": "ougaritique",
            "uk": "ukrainien",
            "umb": "umbundu",
            "und": "langue indéterminée",
            "ur": "ourdou",
            "uz": "ouzbek",
            "vai": "vaï",
            "ve": "venda",
            "vec": "vénitien",
            "vep": "vepse",
            "vi": "vietnamien",
            "vls": "flamand occidental",
            "vmf": "franconien du Main",
            "vmw": "macua",
            "vo": "volapük",
            "vot": "vote",
            "vro": "võro",
            "vun": "vunjo",
            "wa": "wallon",
            "wae": "walser",
            "wal": "walamo",
            "war": "waray",
            "was": "washo",
            "wbp": "warlpiri",
            "wo": "wolof",
            "wuu": "wu",
            "xal": "kalmouk",
            "xh": "xhosa",
            "xmf": "mingrélien",
            "xnr": "kangri",
            "xog": "soga",
            "yao": "yao",
            "yap": "yapois",
            "yav": "yangben",
            "ybb": "yemba",
            "yi": "yiddish",
            "yo": "yoruba",
            "yrl": "nheengatou",
            "yue": "cantonais",
            "za": "zhuang",
            "zap": "zapotèque",
            "zbl": "symboles Bliss",
            "zea": "zélandais",
            "zen": "zenaga",
            "zgh": "amazighe standard marocain",
            "zh": "chinois",
            "zh-Hans": "chinois simplifié",
            "zh-Hant": "chinois traditionnel",
            "zu": "zoulou",
            "zun": "zuñi",
            "zxx": "sans contenu linguistique",
            "zza": "zazaki"
          },
          "narrow": {
          },
          "short": {
            "az": "azéri",
            "en-GB": "anglais (R.-U.)",
            "en-US": "anglais (É.-U.)"
          }
        }
      },
      "region": {
        "long": {
          "001": "Monde",
          "002": "Afrique",
          "003": "Amérique du Nord",
          "005": "Amérique du Sud",
          "009": "Océanie",
          "011": "Afrique occidentale",
          "013": "Amérique centrale",
          "014": "Afrique orientale",
          "015": "Afrique septentrionale",
          "017": "Afrique centrale",
          "018": "Afrique australe",
          "019": "Amériques",
          "021": "Amérique septentrionale",
          "029": "Caraïbes",
          "030": "Asie de l’Est",
          "034": "Asie du Sud",
          "035": "Asie du Sud-Est",
          "039": "Europe du Sud",
          "053": "Australasie",
          "054": "Mélanésie",
          "057": "région micronésienne",
          "061": "Polynésie",
          "142": "Asie",
          "143": "Asie centrale",
          "145": "Asie de l’Ouest",
          "150": "Europe",
          "151": "Europe de l’Est",
          "154": "Europe du Nord",
          "155": "Europe de l’Ouest",
          "202": "Afrique subsaharienne",
          "419": "Amérique latine",
          "AC": "Île de l’Ascension",
          "AD": "Andorre",
          "AE": "Émirats arabes unis",
          "AF": "Afghanistan",
          "AG": "Antigua-et-Barbuda",
          "AI": "Anguilla",
          "AL": "Albanie",
          "AM": "Arménie",
          "AO": "Angola",
          "AQ": "Antarctique",
          "AR": "Argentine",
          "AS": "Samoa américaines",
          "AT": "Autriche",
          "AU": "Australie",
          "AW": "Aruba",
          "AX": "Îles Åland",
          "AZ": "Azerbaïdjan",
          "BA": "Bosnie-Herzégovine",
          "BB": "Barbade",
          "BD": "Bangladesh",
          "BE": "Belgique",
          "BF": "Burkina Faso",
          "BG": "Bulgarie",
          "BH": "Bahreïn",
          "BI": "Burundi",
          "BJ": "Bénin",
          "BL": "Saint-Barthélemy",
          "BM": "Bermudes",
          "BN": "Brunei",
          "BO": "Bolivie",
          "BQ": "Pays-Bas caribéens",
          "BR": "Brésil",
          "BS": "Bahamas",
          "BT": "Bhoutan",
          "BV": "Île Bouvet",
          "BW": "Botswana",
          "BY": "Biélorussie",
          "BZ": "Belize",
          "CA": "Canada",
          "CC": "Îles Cocos",
          "CD": "Congo-Kinshasa",
          "CF": "République centrafricaine",
          "CG": "Congo-Brazzaville",
          "CH": "Suisse",
          "CI": "Côte d’Ivoire",
          "CK": "Îles Cook",
          "CL": "Chili",
          "CM": "Cameroun",
          "CN": "Chine",
          "CO": "Colombie",
          "CP": "Île Clipperton",
          "CR": "Costa Rica",
          "CU": "Cuba",
          "CV": "Cap-Vert",
          "CW": "Curaçao",
          "CX": "Île Christmas",
          "CY": "Chypre",
          "CZ": "Tchéquie",
          "DE": "Allemagne",
          "DG": "Diego Garcia",
          "DJ": "Djibouti",
          "DK": "Danemark",
          "DM": "Dominique",
          "DO": "République dominicaine",
          "DZ": "Algérie",
          "EA": "Ceuta et Melilla",
          "EC": "Équateur",
          "EE": "Estonie",
          "EG": "Égypte",
          "EH": "Sahara occidental",
          "ER": "Érythrée",
          "ES": "Espagne",
          "ET": "Éthiopie",
          "EU": "Union européenne",
          "EZ": "zone euro",
          "FI": "Finlande",
          "FJ": "Fidji",
          "FK": "Îles Malouines",
          "FM": "Micronésie",
          "FO": "Îles Féroé",
          "FR": "France",
          "GA": "Gabon",
          "GB": "Royaume-Uni",
          "GD": "Grenade",
          "GE": "Géorgie",
          "GF": "Guyane française",
          "GG": "Guernesey",
          "GH": "Ghana",
          "GI": "Gibraltar",
          "GL": "Groenland",
          "GM": "Gambie",
          "GN": "Guinée",
          "GP": "Guadeloupe",
          "GQ": "Guinée équatoriale",
          "GR": "Grèce",
          "GS": "Géorgie du Sud-et-les Îles Sandwich du Sud",
          "GT": "Guatemala",
          "GU": "Guam",
          "GW": "Guinée-Bissau",
          "GY": "Guyana",
          "HK": "R.A.S. chinoise de Hong Kong",
          "HM": "Îles Heard-et-MacDonald",
          "HN": "Honduras",
          "HR": "Croatie",
          "HT": "Haïti",
          "HU": "Hongrie",
          "IC": "Îles Canaries",
          "ID": "Indonésie",
          "IE": "Irlande",
          "IL": "Israël",
          "IM": "Île de Man",
          "IN": "Inde",
          "IO": "Territoire britannique de l’océan Indien",
          "IQ": "Irak",
          "IR": "Iran",
          "IS": "Islande",
          "IT": "Italie",
          "JE": "Jersey",
          "JM": "Jamaïque",
          "JO": "Jordanie",
          "JP": "Japon",
          "KE": "Kenya",
          "KG": "Kirghizstan",
          "KH": "Cambodge",
          "KI": "Kiribati",
          "KM": "Comores",
          "KN": "Saint-Christophe-et-Niévès",
          "KP": "Corée du Nord",
          "KR": "Corée du Sud",
          "KW": "Koweït",
          "KY": "Îles Caïmans",
          "KZ": "Kazakhstan",
          "LA": "Laos",
          "LB": "Liban",
          "LC": "Sainte-Lucie",
          "LI": "Liechtenstein",
          "LK": "Sri Lanka",
          "LR": "Liberia",
          "LS": "Lesotho",
          "LT": "Lituanie",
          "LU": "Luxembourg",
          "LV": "Lettonie",
          "LY": "Libye",
          "MA": "Maroc",
          "MC": "Monaco",
          "MD": "Moldavie",
          "ME": "Monténégro",
          "MF": "Saint-Martin",
          "MG": "Madagascar",
          "MH": "Îles Marshall",
          "MK": "Macédoine du Nord",
          "ML": "Mali",
          "MM": "Myanmar (Birmanie)",
          "MN": "Mongolie",
          "MO": "R.A.S. chinoise de Macao",
          "MP": "Îles Mariannes du Nord",
          "MQ": "Martinique",
          "MR": "Mauritanie",
          "MS": "Montserrat",
          "MT": "Malte",
          "MU": "Maurice",
          "MV": "Maldives",
          "MW": "Malawi",
          "MX": "Mexique",
          "MY": "Malaisie",
          "MZ": "Mozambique",
          "NA": "Namibie",
          "NC": "Nouvelle-Calédonie",
          "NE": "Niger",
          "NF": "Île Norfolk",
          "NG": "Nigeria",
          "NI": "Nicaragua",
          "NL": "Pays-Bas",
          "NO": "Norvège",
          "NP": "Népal",
          "NR": "Nauru",
          "NU": "Niue",
          "NZ": "Nouvelle-Zélande",
          "OM": "Oman",
          "PA": "Panama",
          "PE": "Pérou",
          "PF": "Polynésie française",
          "PG": "Papouasie-Nouvelle-Guinée",
          "PH": "Philippines",
          "PK": "Pakistan",
          "PL": "Pologne",
          "PM": "Saint-Pierre-et-Miquelon",
          "PN": "Îles Pitcairn",
          "PR": "Porto Rico",
          "PS": "Territoires palestiniens",
          "PT": "Portugal",
          "PW": "Palaos",
          "PY": "Paraguay",
          "QA": "Qatar",
          "QO": "régions éloignées de l’Océanie",
          "RE": "La Réunion",
          "RO": "Roumanie",
          "RS": "Serbie",
          "RU": "Russie",
          "RW": "Rwanda",
          "SA": "Arabie saoudite",
          "SB": "Îles Salomon",
          "SC": "Seychelles",
          "SD": "Soudan",
          "SE": "Suède",
          "SG": "Singapour",
          "SH": "Sainte-Hélène",
          "SI": "Slovénie",
          "SJ": "Svalbard et Jan Mayen",
          "SK": "Slovaquie",
          "SL": "Sierra Leone",
          "SM": "Saint-Marin",
          "SN": "Sénégal",
          "SO": "Somalie",
          "SR": "Suriname",
          "SS": "Soudan du Sud",
          "ST": "Sao Tomé-et-Principe",
          "SV": "Salvador",
          "SX": "Saint-Martin (partie néerlandaise)",
          "SY": "Syrie",
          "SZ": "Eswatini",
          "TA": "Tristan da Cunha",
          "TC": "Îles Turques-et-Caïques",
          "TD": "Tchad",
          "TF": "Terres australes françaises",
          "TG": "Togo",
          "TH": "Thaïlande",
          "TJ": "Tadjikistan",
          "TK": "Tokelau",
          "TL": "Timor oriental",
          "TM": "Turkménistan",
          "TN": "Tunisie",
          "TO": "Tonga",
          "TR": "Turquie",
          "TT": "Trinité-et-Tobago",
          "TV": "Tuvalu",
          "TW": "Taïwan",
          "TZ": "Tanzanie",
          "UA": "Ukraine",
          "UG": "Ouganda",
          "UM": "Îles mineures éloignées des États-Unis",
          "UN": "Nations Unies",
          "US": "États-Unis",
          "UY": "Uruguay",
          "UZ": "Ouzbékistan",
          "VA": "État de la Cité du Vatican",
          "VC": "Saint-Vincent-et-les Grenadines",
          "VE": "Venezuela",
          "VG": "Îles Vierges britanniques",
          "VI": "Îles Vierges des États-Unis",
          "VN": "Viêt Nam",
          "VU": "Vanuatu",
          "WF": "Wallis-et-Futuna",
          "WS": "Samoa",
          "XA": "pseudo-accents",
          "XB": "pseudo-bidi",
          "XK": "Kosovo",
          "YE": "Yémen",
          "YT": "Mayotte",
          "ZA": "Afrique du Sud",
          "ZM": "Zambie",
          "ZW": "Zimbabwe",
          "ZZ": "région indéterminée"
        },
        "narrow": {
        },
        "short": {
          "GB": "R.-U.",
          "HK": "Hong Kong",
          "MO": "Macao",
          "PS": "Palestine",
          "UN": "ONU",
          "US": "É.-U."
        }
      },
      "script": {
        "long": {
          "Adlm": "adlam",
          "Arab": "arabe",
          "Aran": "nastaliq",
          "Armi": "araméen impérial",
          "Armn": "arménien",
          "Avst": "avestique",
          "Bali": "balinais",
          "Batk": "batak",
          "Beng": "bengali",
          "Blis": "symboles Bliss",
          "Bopo": "bopomofo",
          "Brah": "brâhmî",
          "Brai": "braille",
          "Bugi": "bouguis",
          "Buhd": "bouhide",
          "Cakm": "chakma",
          "Cans": "syllabaire autochtone canadien unifié",
          "Cari": "carien",
          "Cham": "cham",
          "Cher": "cherokee",
          "Cirt": "cirth",
          "Copt": "copte",
          "Cprt": "syllabaire chypriote",
          "Cyrl": "cyrillique",
          "Cyrs": "cyrillique (variante slavonne)",
          "Deva": "dévanagari",
          "Dsrt": "déséret",
          "Egyd": "démotique égyptien",
          "Egyh": "hiératique égyptien",
          "Egyp": "hiéroglyphes égyptiens",
          "Elym": "élymaïque",
          "Ethi": "éthiopique",
          "Geok": "géorgien khoutsouri",
          "Geor": "géorgien",
          "Glag": "glagolitique",
          "Goth": "gotique",
          "Grek": "grec",
          "Gujr": "goudjarâtî",
          "Guru": "gourmoukhî",
          "Hanb": "han avec bopomofo",
          "Hang": "hangûl",
          "Hani": "sinogrammes",
          "Hano": "hanounóo",
          "Hans": "simplifié",
          "Hant": "traditionnel",
          "Hebr": "hébreu",
          "Hira": "hiragana",
          "Hmng": "pahawh hmong",
          "Hmnp": "nyiakeng puachue hmong",
          "Hrkt": "katakana ou hiragana",
          "Hung": "ancien hongrois",
          "Inds": "indus",
          "Ital": "ancien italique",
          "Jamo": "jamo",
          "Java": "javanais",
          "Jpan": "japonais",
          "Kali": "kayah li",
          "Kana": "katakana",
          "Khar": "kharochthî",
          "Khmr": "khmer",
          "Knda": "kannara",
          "Kore": "coréen",
          "Kthi": "kaithî",
          "Lana": "lanna",
          "Laoo": "lao",
          "Latf": "latin (variante brisée)",
          "Latg": "latin (variante gaélique)",
          "Latn": "latin",
          "Lepc": "lepcha",
          "Limb": "limbou",
          "Lina": "linéaire A",
          "Linb": "linéaire B",
          "Lyci": "lycien",
          "Lydi": "lydien",
          "Mand": "mandéen",
          "Mani": "manichéen",
          "Maya": "hiéroglyphes mayas",
          "Mero": "méroïtique",
          "Mlym": "malayalam",
          "Mong": "mongol",
          "Moon": "moon",
          "Mtei": "meitei mayek",
          "Mymr": "birman",
          "Nand": "nandinagari",
          "Nkoo": "n’ko",
          "Ogam": "ogam",
          "Olck": "ol-chiki",
          "Orkh": "orkhon",
          "Orya": "odia",
          "Osma": "osmanais",
          "Perm": "ancien permien",
          "Phag": "phags pa",
          "Phli": "pehlevi des inscriptions",
          "Phlp": "pehlevi des psautiers",
          "Phlv": "pehlevi des livres",
          "Phnx": "phénicien",
          "Plrd": "phonétique de Pollard",
          "Prti": "parthe des inscriptions",
          "Qaag": "zawgyi",
          "Rjng": "rejang",
          "Rohg": "hanifi",
          "Roro": "rongorongo",
          "Runr": "runique",
          "Samr": "samaritain",
          "Sara": "sarati",
          "Saur": "saurashtra",
          "Sgnw": "écriture des signes",
          "Shaw": "shavien",
          "Sinh": "cingalais",
          "Sund": "sundanais",
          "Sylo": "sylotî nâgrî",
          "Syrc": "syriaque",
          "Syre": "syriaque estranghélo",
          "Syrj": "syriaque occidental",
          "Syrn": "syriaque oriental",
          "Tagb": "tagbanoua",
          "Tale": "taï-le",
          "Talu": "nouveau taï-lue",
          "Taml": "tamoul",
          "Tavt": "taï viêt",
          "Telu": "télougou",
          "Teng": "tengwar",
          "Tfng": "tifinagh",
          "Tglg": "tagal",
          "Thaa": "thâna",
          "Thai": "thaï",
          "Tibt": "tibétain",
          "Ugar": "ougaritique",
          "Vaii": "vaï",
          "Visp": "parole visible",
          "Wcho": "wantcho",
          "Xpeo": "cunéiforme persépolitain",
          "Xsux": "cunéiforme suméro-akkadien",
          "Yiii": "yi",
          "Zinh": "hérité",
          "Zmth": "notation mathématique",
          "Zsye": "emoji",
          "Zsym": "symboles",
          "Zxxx": "non écrit",
          "Zyyy": "commun",
          "Zzzz": "écriture inconnue"
        },
        "narrow": {
        },
        "short": {
        }
      }
    }
  },
  "locale": "fr"
})
}